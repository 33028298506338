// @ts-check

import { useEffect, useId, useMemo, useRef, useState } from 'react';

import { useSoup } from './Context';

/**
 * @import { SoupSession } from '@technomiam/soup-client';
 * @import {
 * 	Publication,
 * 	Subscription,
 *  SubscriptionRequest,
 * } from './Context';
 */

/**
 * @typedef {{
 *  deviceIds?: string[],
 *  roomId?: string,
 * }} PublicationFilter
 */

/**
 * @param {PublicationFilter} [filter]
 * @returns {Publication[]}
 */
export const usePublications = (filter) => {
	const { publications } = useSoup();

	const deviceIds = filter?.deviceIds;
	const roomId = filter?.roomId;

	const filteredPublications = useMemo(() => {
		let result = publications;
		if (roomId) {
			result = result.filter((publication) => publication.roomId === roomId);
		}
		if (!deviceIds) return result;
		if (deviceIds && deviceIds.length < 1) return [];
		return result.filter((publication) => {
			if (deviceIds) {
				if (!publication.appData.device?.deviceId) return false;
				return deviceIds.includes(publication.appData.device?.deviceId);
			}
			return true;
		});
	}, [deviceIds, publications, roomId]);

	return filteredPublications;
};

/** @type {Map<Subscription['producerId'], string[]>} */
const souscriptionMap = new Map();

/**
 * @param {SoupSession} soup
 * @param {string} reactId
 * @param {Publication} publication
 * @param {Parameters<SoupSession['subscribe']>[1]} params
 * @return {Promise<Subscription | undefined>}
 */
const subscribe = async (soup, reactId, publication, params) => {
	const reactIds = souscriptionMap.get(publication.producerId) || [];
	if (!reactIds.includes(reactId)) {
		reactIds.push(reactId);
	}

	souscriptionMap.set(
		publication.producerId,
		reactIds,
	);

	return soup.subscribe(publication, params);
};

/**
 * @param {SoupSession} soup
 * @param {string} reactId
 * @param {Pick<Subscription, 'producerId' | 'roomId'>} subcription
 * @return {Promise<void>}
 */
const unsubscribe = async (soup, reactId, subcription) => {
	const reactIds = souscriptionMap.get(subcription.producerId) || [];
	const index = reactIds.indexOf(reactId);
	console.log('unsubscribe', subcription);
	if (index > -1) {
		reactIds.splice(index, 1);
	}

	if (reactIds.length < 1) {
		console.log('REAL unsubscribe', subcription, reactId);
		souscriptionMap.delete(subcription.producerId);
		return soup.unsubscribe(subcription);
	}

	console.log('FALSE unsubscribe', subcription, reactId);

	souscriptionMap.set(
		subcription.producerId,
		reactIds,
	);

	return undefined;
};

/**
 * This hook is used to keep the same reference of the subscriptionRequests
 * to avoid subscribing multiple times if the requests are the same
 * but the array reference changes.
 * @param {SubscriptionRequest[]} subscriptionRequests
 * @returns {SubscriptionRequest[]}
 */
const useImmutableSubscriptionRequests = (subscriptionRequests) => {
	const subscriptionRequestsRef = useRef(
		/** @type {SubscriptionRequest[] | undefined} */(undefined)
	);

	const immutableSubscriptionRequests = useMemo(() => {
		if (subscriptionRequests === subscriptionRequestsRef.current) {
			return subscriptionRequests;
		}
		if (
			!subscriptionRequestsRef.current
			|| !subscriptionRequests
			|| subscriptionRequestsRef.current.length !== subscriptionRequests.length
		) {
			subscriptionRequestsRef.current = subscriptionRequests;
			return subscriptionRequests;
		}

		if (JSON.stringify(subscriptionRequestsRef.current) === JSON.stringify(subscriptionRequests)) {
			const result = subscriptionRequestsRef.current;
			return result;
		}

		subscriptionRequestsRef.current = subscriptionRequests;
		return subscriptionRequestsRef.current;
	}, [subscriptionRequests]);

	return immutableSubscriptionRequests;
};

/**
 * @param {SubscriptionRequest[]} subscriptionRequests
 * @returns {Subscription[]}
 */
export const useSubscribe = (subscriptionRequests) => {
	const reactId = useId();
	const { soupSession } = useSoup();
	const [subscriptions, setSubscriptions] = useState(/** @type {Subscription[]} */([]));

	const soupSessionRef = useRef(soupSession);
	soupSessionRef.current = soupSession;

	const subscriptionsRef = useRef(subscriptions);
	subscriptionsRef.current = subscriptions;

	const immutableSubscriptionRequests = useImmutableSubscriptionRequests(subscriptionRequests);

	const subscriptionRequestsRef = useRef(immutableSubscriptionRequests);
	subscriptionRequestsRef.current = immutableSubscriptionRequests;

	useEffect(() => {
		if (!soupSession) {
			setSubscriptions((s) => (s.length < 1 ? s : []));
			return undefined;
		}

		/**
		 * @param {Subscription} subscription
		 * @returns {boolean}
		 */
		const isSubscriptionStillRequested = (subscription) => (
			subscriptionRequestsRef.current.some(
				(p) => p.publication.producerId === subscription.producerId,
			)
		);

		/**
		 * @param {Publication} publication
		 * @returns {boolean}
		 */
		const isPublicationSubscribed = (publication) => (
			subscriptionsRef.current.some(
				(p) => p.producerId === publication.producerId,
			)
		);

		const newSubscriptionRequests = immutableSubscriptionRequests.filter((subscriptionRequest) => {
			const exists = subscriptionsRef.current.find((subscription) => (
				subscription.producerId === subscriptionRequest.publication.producerId
			));
			return !exists;
		});

		const oldSubscriptions = subscriptionsRef.current.filter((subscription) => {
			const exists = immutableSubscriptionRequests.find((subscriptionRequest) => (
				subscription.producerId === subscriptionRequest.publication.producerId
			));
			return !exists;
		});

		console.log({
			immutableSubscriptionRequests,
			newSubscriptionRequests,
			oldSubscriptions,
			'subscriptionsRef.current': subscriptionsRef.current,
		});

		oldSubscriptions.forEach((subscription) => {
			unsubscribe(soupSession, reactId, subscription);
			setSubscriptions((state) => state.filter((p) => p.producerId !== subscription.producerId));
		});

		/**
		 * @param {Subscription} subscription
		 * @returns {void}
		 */
		const onTrackSubscribed = (subscription) => {
			if (!isSubscriptionStillRequested(subscription)) {
				return;
			}
			console.log({
				onTrackSubscribed: subscription,
				immutableSubscriptionRequests,
			});
			setSubscriptions((state) => {
				const index = state.findIndex((p) => p.producerId === subscription.producerId);
				if (index < 0) return [...state, subscription];
				return state;
			});
		};

		/**
		 * @param {{ roomId: string }} param0
		 * @returns {void}
		 */
		const onSoupConnected = ({ roomId }) => {
			console.log({ newSubscriptionRequests, roomId, reactId });
			Promise.all(newSubscriptionRequests
				.filter((subscriptionRequest) => (subscriptionRequest.publication.roomId === roomId))
				.map((subscriptionRequest) => {
					console.log({
						SUBSCRIBE: subscriptionRequest,
					});
					return subscribe(
						soupSession,
						reactId,
						subscriptionRequest.publication,
						subscriptionRequest.preferredLayers,
					);
				})).then(
				(subs) => subs
					.filter((sub) => !!sub)
					.forEach((sub) => {
						/* We check current producers,
						 * because the request may have changed
						 * before the subscribe promise resolves.
						 * In this case, we unsubscribe the outdated subscription.
						 */
						if (!isSubscriptionStillRequested(sub)) {
							unsubscribe(soupSession, reactId, sub);
						} else {
							console.log('onSoupConnected', { sub, roomId, reactId });
							onTrackSubscribed(sub);
						}
					}),
			);
		};

		/**
		 * @param {{ roomId: string }} param0
		 * @returns {void}
		 */
		const onSoupDisconnected = ({ roomId }) => {
			setSubscriptions((s) => (
				s.length < 1
					? s
					: s.filter((sub) => sub.roomId !== roomId)
			));
		};

		/**
		 * @param {Publication} publication
		 */
		const onTrackUnsubscribed = (publication) => {
			if (!isPublicationSubscribed(publication)) {
				return;
			}
			console.log({
				onTrackUnsubscribed: publication,
			});
			setSubscriptions((state) => state.filter((p) => p.producerId !== publication.producerId));
		};

		/**
		 * @param {Publication} publication
		 */
		const onTrackUpdated = (publication) => {
			if (!isPublicationSubscribed(publication)) {
				return;
			}
			console.log({
				onTrackUpdated: publication,
			});
			setSubscriptions((state) => state.map((subscription) => (
				subscription.producerId === publication.producerId
					? {
						...subscription,
						appData: {
							...subscription.appData,
							...publication.appData,
						},
					}
					: subscription
			)));
		};

		soupSession.on('trackSubscribed', onTrackSubscribed);
		soupSession.on('trackUnsubscribed', onTrackUnsubscribed);
		soupSession.on('trackUpdated', onTrackUpdated);
		soupSession.on('soup:connected', onSoupConnected);
		soupSession.on('soup:disconnected', onSoupDisconnected);

		soupSession.soupServers.forEach((server) => {
			if (server.status === 'connected') {
				onSoupConnected({ roomId: server.roomId });
			}
		});

		return function removeSoupListeners() {
			soupSession.off('trackSubscribed', onTrackSubscribed);
			soupSession.off('trackUnsubscribed', onTrackUnsubscribed);
			soupSession.off('trackUpdated', onTrackUpdated);
			soupSession.off('soup:connected', onSoupConnected);
			soupSession.off('soup:disconnected', onSoupDisconnected);
		};
	}, [
		reactId,
		soupSession,
		immutableSubscriptionRequests,
	]);

	const reactIdRef = useRef(reactId);
	reactIdRef.current = reactId;

	useEffect(() => () => {
		if (!soupSessionRef.current) return;
		const session = soupSessionRef.current;
		subscriptionsRef.current.forEach((subscription) => {
			unsubscribe(session, reactIdRef.current, subscription);
		});
	}, []);

	return subscriptions;
};

/**
 * @param {Subscription[]} subscriptions
 * @returns {MediaStream}
 */
export const useMediastream = (subscriptions) => {
	const mediastreamRef = useRef(/** @type {MediaStream | undefined} */(undefined));
	const mediastream = useMemo(() => {
		const subscribedTracks = subscriptions.map(({ mediaStreamTrack }) => mediaStreamTrack);

		if (!mediastreamRef.current) {
			mediastreamRef.current = new MediaStream(subscribedTracks);
			return mediastreamRef.current;
		}

		const mediastreamTracks = mediastreamRef.current.getTracks();

		/**
		 * To remove mediastream tracks, we must create a new mediastream
		 * because the player continues to play the old audio when
		 * the audio track is replaced by another.
		 * Weird !
		 */
		if (mediastreamTracks.find((track) => !subscribedTracks.find((t) => t.id === track.id))) {
			mediastreamRef.current = new MediaStream(subscribedTracks);
			return mediastreamRef.current;
		}

		if (subscribedTracks.find((track) => !mediastreamTracks.find((t) => t.id === track.id))) {
			mediastreamRef.current = new MediaStream(subscribedTracks);
			return mediastreamRef.current;
		}

		/* Replaced by the if above because it generates this warning :
		Warning: Cannot update a component (`PlayerLiveVuMeter`) while rendering
		a different component (`ChannelPlayer`). To locate the bad setState() call
		inside `ChannelPlayer`, follow the stack trace as described
		in https://reactjs.org/link/setstate-in-render */
		// subscribedTracks.forEach((track) => {
		// 	if (
		// 		mediastreamTracks.find((t) => t.id === track.id)
		// 		|| !mediastreamRef.current
		// 	) return;

		// 	mediastreamRef.current.addTrack(track);
		// 	mediastreamRef.current.dispatchEvent(new MediaStreamTrackEvent(
		// 		'addtrack',
		// 		{ track },
		// 	));
		// });

		return mediastreamRef.current;
	}, [subscriptions]);

	return mediastream;
};

/**
 * @param {SubscriptionRequest[]} subscriptionRequests
 * @returns {MediaStream}
 */
export const useSubscribeMediastream = (subscriptionRequests) => {
	const subscriptions = useSubscribe(subscriptionRequests);
	console.log({ subscriptions });
	const mediastream = useMediastream(subscriptions);
	console.log({ mediastream });
	return mediastream;
};
