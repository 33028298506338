import { ChatStatus } from '../../Channel/Chat/Provider';
import { Orientation } from '../../Studio/Orientation';

export const AdTriggerType = {
	MANUAL: 'MANUAL',
	AUTOMATIC: 'AUTOMATIC',
};

export const studioScheduleSimulCastsSchema = {
	type: 'array',
	items: {
		type: 'object',
		additionalProperties: false,
		properties: {
			_id: { type: 'string' },
			name: { type: 'string' },
			platformKey: { type: 'string' },
			streamKey: { type: 'string' },
			url: { type: 'string' },
		},
		required: [
			'_id',
			'name',
			'platformKey',
			'streamKey',
			'url',
		],
	},
};

export const studioScheduleManagedEditSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		allowRestream: { type: 'boolean' },
		banner: {
			oneOf: [
				{ type: 'string' },
				{
					type: 'object',
					properties: {
						blob: { type: 'object' },
					},
					required: ['blob'],
				},
			],
		},
		category: {
			type: 'string',
		},
		chatStatus: {
			type: 'string',
			enum: Object.values(ChatStatus),
		},
		description: {
			type: 'string',
			maxLength: 20000,
		},
		isPublic: {
			type: 'boolean',
		},
		isRecordEnabled: {
			type: 'boolean',
		},
		orientation: {
			type: 'string',
			enum: Object.values(Orientation),
		},
		participants: {
			type: 'array',
		},
		password: {
			type: 'string',
			minLength: 6,
		},
		plannedDuration: {
			type: 'number',
			minimum: 1,
			maximum: 60 * 24,
		},
		price: {
			type: 'object',
			additionalProperties: false,
			properties: {
				participant: {
					type: 'number',
					minimum: 0,
				},
				viewer: {
					type: 'number',
					minimum: 0,
				},
				participantPerMinute: {
					type: 'number',
					minimum: 0,
				},
				viewerPerMinute: {
					type: 'number',
					minimum: 0,
				},
			},
		},
		currencies: {
			type: 'object',
			additionalProperties: false,
			properties: {
				participant: {
					type: 'array',
					items: { type: 'string' },
				},
				viewer: {
					type: 'array',
					items: { type: 'string' },
				},
				participantPerMinute: {
					type: 'array',
					items: { type: 'string' },
				},
				viewerPerMinute: {
					type: 'array',
					items: { type: 'string' },
				},
			},
		},
		startImmediately: {
			type: 'boolean',
		},
		startAt: {
			type: 'string',
			format: 'date-time',
		},
		subject: {
			type: 'string',
			maxLength: 140,
		},
		timezone: {
			type: 'string',
		},
		simulCast: studioScheduleSimulCastsSchema,
		audienceType: {
			type: 'string',
		},
		presale: {
			type: 'object',
			additionalProperties: false,
			properties: {
				participant: {
					type: 'object',
					additionalProperties: false,
					properties: {
						active: { type: 'boolean' },
						discount: { type: 'boolean' },
						price: { type: 'number' },
						limitedDates: { type: 'boolean' },
						startDate: {
							type: 'string',
							format: 'date-time',
						},
						endDate: {
							type: 'string',
							format: 'date-time',
						},
						limitedTickets: { type: 'boolean' },
						ticketLimit: { type: 'number' },
						ticketsSold: { type: 'number' },
					},
					required: ['active', 'discount', 'price', 'limitedDates', 'limitedTickets', 'ticketLimit', 'ticketsSold'],
				},
				viewer: {
					type: 'object',
					additionalProperties: false,
					properties: {
						active: { type: 'boolean' },
						discount: { type: 'boolean' },
						price: { type: 'number' },
						limitedDates: { type: 'boolean' },
						startDate: {
							type: 'string',
							format: 'date-time',
						},
						endDate: {
							type: 'string',
							format: 'date-time',
						},
						limitedTickets: { type: 'boolean' },
						ticketLimit: { type: 'number' },
						ticketsSold: { type: 'number' },
					},
					required: ['active', 'discount', 'price', 'limitedDates', 'limitedTickets', 'ticketLimit', 'ticketsSold'],
				},
			},
		},
		adSettings: {
			type: 'object',
			additionnalProperties: false,
			properties: {
				enableAds: { type: 'boolean' },
				adTriggerType: {
					type: 'string',
					enum: Object.values(AdTriggerType),
				},
				adTriggerInterval: { type: 'string' },
				adBreakLength: { type: 'string' },
			},
		},
		vodSettings: {
			type: 'object',
			additionnalProperties: false,
			properties: {
				publishPublicRecToVod: { type: 'boolean' },
			},
		},
		watchLinkId: {
			type: 'string',
		},
	},
	required: [
		'password',
	],
};
